.keyart__bgContainer {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.rotator__li {
  overflow: hidden;
}

.rotator__li.seq-in {
  overflow: unset;
}